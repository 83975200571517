export const educationsData = [
    {
        experienceType : "Doctorate - Continue",
        graduationFromFirstLine : "Atatürk University",
        isHaveBrTag: true,
        graduationFromSecondLine : "Computer Engineering"
    },
    {
        experienceType : "Master",
        graduationFromFirstLine : "Atatürk University",
        isHaveBrTag: true,
        graduationFromSecondLine : "Computer Engineering"
    },
    {
        experienceType : "Undergraduate",
        graduationFromFirstLine : "Karadeniz Technical University",
        isHaveBrTag: true,
        graduationFromSecondLine : "Computer Engineering"
    },
    {
        experienceType : "High School",
        graduationFromFirstLine : "Erzurum İbrahim Hakkı Science High School",
        isHaveBrTag: false,
        graduationFromSecondLine : ""
    }
];

export const jobsData = [
    {
        experienceType : "Research Assistant",
        graduationFromFirstLine : "Atatürk University - Software Engineering",
        isHaveBrTag: false,
        graduationFromSecondLine : ""
    },
    {
        experienceType : "Solution Architect",
        graduationFromFirstLine : "Quorion Data Systems GMBH",
        isHaveBrTag: false,
        graduationFromSecondLine : ""
    },
    {
        experienceType : "Full Stack Developer",
        graduationFromFirstLine : "Teknar Technology",
        isHaveBrTag: false,
        graduationFromSecondLine : ""
    },
    {
        experienceType : "Intern",
        graduationFromFirstLine : "Unicrow",
        isHaveBrTag: false,
        graduationFromSecondLine : ""
    },
    {
        experienceType : "Intern",
        graduationFromFirstLine : "Karadeniz Technical University",
        isHaveBrTag: false,
        graduationFromSecondLine : ""
    }
]
