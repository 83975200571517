const WorkCard = ({ category, imageSource, title, gitHubLink, liveDemoLink, isDemo }) => {
    
    const checkLinkExistence = e => {
        if(gitHubLink.length < 1)
        e.preventDefault();
    }
    return (
        <div className = {`work__card mix ${category}`}>
            <img src={imageSource} alt="" className="work__img" />

            <h3 className="work__title">{title}</h3>
            <div className="work__buttons">
                <a
                    href={gitHubLink}
                    className={`work__button github ${gitHubLink.length < 1 ? "disabled_link" : ""}`}
                    target="_blank" rel='noreferrer'
                    onClick={e => checkLinkExistence(e)}
                >
                    <i className='bx bxl-github'></i>
                </a>
                {
                    liveDemoLink.length > 0 ?
                    <a href={liveDemoLink} className="work__button" target="_blank" rel='noreferrer'>
                        {isDemo ? "Live Demo" : "Go to App"}
                    </a> : null
                }
                
            </div>
        </div>
    )
}

export default WorkCard