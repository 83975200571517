import "./assets/css/base.css";
import "./assets/css/theme.css";
import "./assets/css/buttons.css";
import "./assets/css/scrollbar.css";

import About from "./components/About/About";
import Experiences from "./components/Experiences/Experiences";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Skills from "./components/Skills/Skills";
import Testimonials from "./components/Testimonials/Testimonials";
import Work from "./components/Work/Work";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import { useEffect, useRef, useState } from "react";
import Docs from "./components/Docs/Docs";

function App() {
  
  const aboutSectionRef = useRef(null);
  const homeSectionRef = useRef(null);
  const skillsSectionRef = useRef(null);
  const experiencesSectionRef = useRef(null);
  const docsSectionRef = useRef(null);
  const workSectionRef = useRef(null);
  const testimonialsSectionRef = useRef(null);
  const contactSectionRef = useRef(null);

  const [sections, setSections] = useState([]);

  useEffect(() => {
    const sectionElements = [];
    sectionElements.push(aboutSectionRef.current);
    sectionElements.push(homeSectionRef.current);
    sectionElements.push(skillsSectionRef.current);
    sectionElements.push(experiencesSectionRef.current);
    sectionElements.push(docsSectionRef.current);
    sectionElements.push(workSectionRef.current);
    sectionElements.push(testimonialsSectionRef.current);
    sectionElements.push(contactSectionRef.current);
    setSections(sectionElements);
    
  }, [aboutSectionRef, homeSectionRef, skillsSectionRef, experiencesSectionRef, docsSectionRef, workSectionRef, testimonialsSectionRef, contactSectionRef]);
  
  return (
    <>
      <Header sections = { sections }/>
      <main className="main">
        <Home homeSectionRef = {homeSectionRef}/>
        <About aboutSectionRef = {aboutSectionRef}/>
        <Skills skillsSectionRef = { skillsSectionRef }/>
        <Experiences experiencesSectionRef = {experiencesSectionRef}/>
        <Docs docsSectionRef = {docsSectionRef}/>
        <Work workSectionRef = {workSectionRef}/>
        <Testimonials testimonialsSectionRef = {testimonialsSectionRef}/>
        <Contact contactSectionRef = {contactSectionRef} />
      </main>
      <Footer/>
    </>
  );
}

export default App;
