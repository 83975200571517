const ContactForm = () => {
    
    let makeMailContent = e => {
        e.preventDefault();
        const mailerNameElement = document.querySelector("#mailerNameInput");
        const mailerSubjectElement = document.querySelector("#mailerSubjectInput");
        const mailerMessageElement = document.querySelector("#mailerMessageInput");
    
        let mailerName = mailerNameElement.value;
        let subject = mailerSubjectElement.value;
        let mailerMessage = mailerMessageElement.value;
    
        let body = `${mailerMessage}%0D%0A%0D%0ABest Regards%0D%0A%0D%0A${mailerName}`;
        let targetEmail = "osmandurdag@hotmail.com";
        let mailContent = `mailto:${targetEmail}?subject=${subject}&body=${body}`;
        window.open(mailContent);
    };

    return (
        <form action="" className="contact__form">
            <div className="contact__form-div">
                <label htmlFor="" className="contact__form-tag">Name</label>
                <input id="mailerNameInput" type="text" className="contact__form-input" placeholder="Insert your full name..." />
            </div>

            <div className="contact__form-div">
                <label htmlFor="" className="contact__form-tag">Subject</label>
                <input id="mailerSubjectInput" type="text" className="contact__form-input" placeholder="Write the subject..." />
            </div>

            <div className="contact__form-div contact__form-area">
                <label htmlFor="" className="contact__form-tag">Message</label>
                <textarea id="mailerMessageInput" cols="30" rows="10" className="contact__form-input" placeholder="Write your message..."></textarea>
            </div>
            <div className='contact__form-sendMessageButtonDiv'>
                <button onClick={(e) => makeMailContent(e)} className="button">Send Message</button>
            </div>
        </form>
    )
}

export default ContactForm