const SkillData = ({ experienceType, graduationFromFirstLine, isHaveBrTag, graduationFromSecondLine }) => {
    return (
        <div className="skill__data">
            <div>
                <h3 className="skills__name">
                    <i className='bx bxs-graduation'></i> {experienceType}
                </h3>
                <span className="skills__level">
                    {graduationFromFirstLine}
                    {isHaveBrTag ? <br /> : ""}
                    {graduationFromSecondLine}
                </span>
            </div>
        </div>
    )
}

export default SkillData