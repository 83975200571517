import { useState } from 'react'
import "./Work.css";
import WorkCard from './WorkCard';
import { WorkCardsData } from '../../data/WorkCardsData';

const Work = ({ workSectionRef }) => {

    const [worksCardDataCopy, setWorksCardDataCopy] = useState(WorkCardsData);

    const setActiveWork = element => {
        const siblingSpans = element.parentNode.childNodes;
        siblingSpans.forEach(siblingSpan => {
            siblingSpan.classList.remove("active-work");
        });
        element.classList.add("active-work");

        const category = element.innerText.toLowerCase();

        if (category === "all"){
            setWorksCardDataCopy(WorkCardsData);
        }
        else{
            const filtered = WorkCardsData.filter(workCardData => workCardData.category === category);
            setWorksCardDataCopy(filtered);
        }
    };

    return (
        <section className="work section" id="work" ref={workSectionRef}>
            <span className="section__subtitle"></span>
            <h2 className="section__title">Recent Works</h2>

            <div className="work__filters">
                <span className="work__item active-work" onClick={(element) => setActiveWork(element.target)}>All</span>
                <span className="work__item" onClick={(element) => setActiveWork(element.target)}>Web</span>
                <span className="work__item" onClick={(element) => setActiveWork(element.target)}>Mobile</span>
                <span className="work__item" onClick={(element) => setActiveWork(element.target)}>Desktop</span>
                <span className="work__item" onClick={(element) => setActiveWork(element.target)}>Game</span>
            </div>

            <div className="work__container container grid">
                {worksCardDataCopy.map((workCardData, index) => (
                    <WorkCard
                        key = {`workCard-${index}`}
                        category = {workCardData.category}
                        imageSource = {workCardData.imageSource}
                        title = {workCardData.title}
                        gitHubLink = {workCardData.gitHubLink}
                        liveDemoLink = {workCardData.liveDemoLink}
                        isDemo = {workCardData.isDemo}
                    />
                ))}

                { /*
                    <div className="work__card mix mobile">
                        <img src="./assets/img/work2.jpg" alt="" className="work__img" />

                        <h3 className="work__title">App Mobile</h3>


                        <a href="#" className="work__button">
                            Demo <i className="bx bx-right-arrow-alt work__icon"></i>
                        </a>
                    </div>
                    <div className="work__card mix desktop">
                        <img src="./assets/img/work3.jpg" alt="" className="work__img" />

                        <h3 className="work__title">Brand Design</h3>

                        <a href="#" className="work__button">
                            Demo <i className="bx bx-right-arrow-alt work__icon"></i>
                        </a>
                    </div>

                    <div className="work__card mix mobile">
                        <img src="./assets/img/work5.jpg" alt="" className="work__img" />

                        <h3 className="work__title">App Mobile</h3>

                        <a href="#" className="work__button">
                            Demo <i className="bx bx-right-arrow-alt work__icon"></i>
                        </a>
                    </div>
                */ }
            </div>
        </section>
    )
}

export default Work