import "./Contact.css";
import { contactCardsData } from '../../data/ContactCardsData';
import ContactCard from './ContactCard';
import ContactForm from './ContactForm';

const Contact = ({ contactSectionRef }) => {
    
    return (
        <section className="contact section" id="contact" ref = { contactSectionRef }>
            <span className="section__subtitle"></span>
            <h2 className="section__title">Contact Me</h2>

            <div className="contact__container container grid">
                <div className="contact__content">
                    <h3 className="contact__title"> </h3> 

                    <div className="contact__info">
                        {contactCardsData.map((contactCardData, index) => (
                            <ContactCard
                                key = {`contactCard-${index}`}
                                linkTo={contactCardData.linkTo}
                                icon = {contactCardData.icon}
                                title = {contactCardData.title}
                                data = {contactCardData.data}
                            />
                        ))}
                    </div>
                </div>
                <div className="contact__content">
                    <h3 className="contact__title">Write Me</h3>

                    <ContactForm />
                </div>
            </div>
        </section>
    )
}

export default Contact