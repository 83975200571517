import "./Skills.css";
import SkillsContent from './SkillsContent';
import { skillsContentData } from '../../data/SkillsContentData';

const Skills = ({ skillsSectionRef }) => {
    
    return (
        <section className="skills section" id="skills" ref = { skillsSectionRef }>
            <span className="section__subtitle">

            </span>
            <h2 className="section__title">My Abilities</h2>

            <div className="skills__container container grid">
                {skillsContentData.map((skillsContent, index) => (
                    <SkillsContent
                        key = {`skillsContent-${index}`}
                        title = {skillsContent.title}
                        firstGroup = {skillsContent.firstGroup}
                        secondGroup = {skillsContent.secondGroup}
                        thirdGroup = {skillsContent.thirdGroup}
                    />
                ))}
            </div>


        </section>
    )
}

export default Skills