import DocsCard from "./DocsCard";
import { DocsCardData } from "../../data/DocsCardsData";
import "./Docs.css";

const Docs = ({ docsSectionRef }) => {
    const DOCS_BASE_URL = "https://osmandurdag.com/docs";
    
    return (
        <section className="docs section" id="docs" ref={docsSectionRef}>
            <h2 className="section__title">Documentations</h2>
            <div className="docs__container container grid">
                {DocsCardData.map((cardData, index) => (
                    <DocsCard
                        key={`docsCard-${index}`}
                        title={cardData.title}
                        description={cardData.description}
                        url={cardData.url}
                        gitHubLink={cardData.githubLink}
                    />
                ))}
            </div>
            <a href={DOCS_BASE_URL} target="_blank" rel="noreferrer" className="button docs_show_all_button">Show All Documentations</a>
        </section>
    )
}

export default Docs