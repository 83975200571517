const NavItem = ({linkTo, isActive, iconClass}) => {
    return (
        <li className = "nav__item">
            <a href = {`#${linkTo}`} className = {`nav__link ${isActive ? "active-link" : ""}`}>
                <i className = {iconClass}></i>
            </a>
        </li>
    )
}

export default NavItem