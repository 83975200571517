import 'swiper/css';
import 'swiper/css/pagination';
import "./Testimonials.css";
import { testimonialCardsData } from '../../data/TestimonialCardsData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import TestimonialCard from './TestimonialCard';

const Testimonials = ({ testimonialsSectionRef }) => {

    return (
        <section className="testimonial section" ref = { testimonialsSectionRef }>
            <span className="section__subtitle"></span>
            <h2 className="section__title">Testimonial</h2>

            <Swiper
                modules={[Pagination]}
                className = "testimonial__container container swiper"
                spaceBetween = {24}
                loop = {true}
                grabCursor = {true}
                pagination = {{
                    el : ".swiper-pagination",
                    clickable : true
                }}
                breakpoints = {{
                    576 : {
                        slidesPerView : 2
                    },
                    768 : {
                        slidesPerView : 2,
                        spaceBetween : 48
                    }
                }}
            >
                <div className="swiper-wrapper">
                    {testimonialCardsData.map((testimonialCardData, index) => (
                        <SwiperSlide
                            key = {`testimonialCard-${index}`}
                            className="testimonial__card swiper-slide"
                        >
                            <TestimonialCard
                                imageSource = {testimonialCardData.imageSource}
                                name = {testimonialCardData.name}
                                description = {testimonialCardData.description}
                            />
                        </SwiperSlide>
                    ))}
                </div>
                <div className="swiper-pagination"></div>
            </Swiper>
        </section>
    )
}

export default Testimonials