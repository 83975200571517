const ContactCard = ({ linkTo, icon, title, data}) => {
    return (
        <div className="contact__card">
            <a href={linkTo} className="contact__button" target="_blank" rel="noreferrer">
                <i className={`${icon} contact__card-icon`}></i>
                <h3 className="contact__card-title">{title}</h3>
                <span className="contact__card-data">
                    {data}
                </span>
            </a>
        </div>
    )
}

export default ContactCard