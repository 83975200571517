import "./About.css";
import profileImage from "../../assets/img/profile2.webp";
import AboutBox from './AboutBox';
import { aboutBoxesData } from '../../data/AboutBoxesData';

const About = ({ aboutSectionRef }) => {

    return (
        <section className="about section" id="about" ref={ aboutSectionRef }>
            <span className="section__subtitle">

            </span>
            <h2 className="section__title">
                About Me
            </h2>

            <div className="about__container container grid">
                <img src={profileImage} alt="" className="about__img" />

                <div className="about__data">
                    <div className="about__info">
                        {aboutBoxesData.map((aboutBoxData, index) => (
                            <AboutBox
                                key = {`aboutBox-${index}`}
                                boxIcon = {aboutBoxData.boxIcon}
                                title = {aboutBoxData.title}
                                subTitle = {aboutBoxData.subTitle}
                            />
                        ))}
                    </div>

                    <p className="about__description">
                        Computer Engineer & Software Developer
                        <br /><br />
                        I completed my undergraduate education by graduating from Karadeniz Technical University Computer Engineering department. Currently, I am a research assistant at Atatürk University.
                    </p>

                    <a href="#contact" className="button">Contact Me</a>
                </div>
            </div>
        </section>
    )
}

export default About