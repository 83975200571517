import SkillsData from './SkillsData'

const SkillsContent = ({ title, firstGroup, secondGroup, thirdGroup }) => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">{ title }</h3>

            <div className="skills__box">
                <div className="skills__group">
                    {firstGroup.map((firstGroupData, index) => (
                        <SkillsData
                            key = {`firstGroupData-${index}`}
                            name = {firstGroupData.name}
                            level = {firstGroupData.level}
                        />
                    ))}
                </div>
                <div className="skills__group">
                    {secondGroup.map((secondGroupData, index) => (
                        <SkillsData
                            key = {`secondGroupData-${index}`}
                            name = {secondGroupData.name}
                            level = {secondGroupData.level}
                        />
                    ))}
                </div>

                <div className="skills__group">
                    {thirdGroup.map((thirdGroupData, index) => (
                        <SkillsData
                            key = {`thirdGroupData-${index}`}
                            name = {thirdGroupData.name}
                            level = {thirdGroupData.level}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SkillsContent