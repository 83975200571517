import testimonialImage1 from "../assets/img/testimonial1.webp";
import testimonialImage2 from "../assets/img/testimonial2.webp";
import testimonialImage3 from "../assets/img/testimonial3.webp";

export const testimonialCardsData = [
    {
        imageSource : testimonialImage1,
        name : "Anonymous",
        description : "References will be added later"
    },
    {
        imageSource : testimonialImage2,
        name : "Anonymous",
        description : "References will be added later"
    },
    {
        imageSource : testimonialImage3,
        name : "Anonymous",
        description : "References will be added later"
    },
    {
        imageSource : testimonialImage3,
        name : "Anonymous",
        description : "References will be added later"
    }
]