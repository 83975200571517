const AboutBox = ({ boxIcon, title, subTitle }) => {
    const startedWorkingYear = 2019;
    const currentYear = new Date().getFullYear();
    return (
        <div className="about__box">
            <i className={`${boxIcon} about__icon`}></i>
            <h3 className="about__title">
                {title}
            </h3>
            <span className="about__subtitle">
                {boxIcon.includes("award") ? `${currentYear - startedWorkingYear} Years Working` : subTitle}
            </span>
        </div>
    )
}

export default AboutBox