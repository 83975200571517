import { useEffect, useState } from 'react';
import "./Header.css";
import NavItem from './NavItem';

const Header = ({ sections }) => {

    const [sectionsActivity, setSectionsActivity] = useState({
        home : true,
        about : false,
        skills : false,
        experiences : false,
        docs : false,
        work : false,
        contact : false
    });

    const [scrollHeader, setScrollHeader] = useState("");

    const scrollHeaderAndActiveLinks = () => {

        const scrollY = window.scrollY;

        if(scrollY >= 50) setScrollHeader("scroll-header"); else setScrollHeader("");

        if (sections && sections.length > 0) {
            const newDict = {};

            sections.forEach(section => {
                const sectionHeight = section.offsetHeight,
                    sectionTop = section.offsetTop - 58,
                    sectionId = section.getAttribute("id");
                
                if(scrollY > sectionTop && scrollY <= sectionTop + sectionHeight){
                    newDict[`${sectionId}`] = true;
                } else{
                    setSectionsActivity({...sectionsActivity, [`${sectionId}`]: false})
                    newDict[`${sectionId}`] = false;
                }
                
            })
            setSectionsActivity(newDict);
        }
    };

    window.addEventListener("scroll", scrollHeaderAndActiveLinks);

    const lightTheme = 'light-theme';
    const iconTheme = 'bx-sun';

    const getCurrentTheme = () => document.body.classList.contains(lightTheme) ? 'light' : 'dark';

    let changeTheme = (element) => {
        const themeButton = element.target;

        document.body.classList.toggle(lightTheme);
        themeButton.classList.toggle(iconTheme);
        localStorage.setItem('selected-theme', getCurrentTheme());
    };


    useEffect(() => {
        const selectedTheme = localStorage.getItem('selected-theme');
    
        if (selectedTheme) {
            const themeButton = document.getElementById('theme-button');

            if(selectedTheme === "light"){
                document.body.classList.add(lightTheme);
                themeButton.classList.remove(iconTheme);
            }
            else{
                document.body.classList.remove(lightTheme);
                themeButton.classList.add(iconTheme);
            }
        }
        
    }, []);

    return (
        <header className={`header ${scrollHeader}`} id="header">
            <nav className="nav container">
                <h1>
                    <a href="#home" className="nav__logo">Osman DURDAĞ</a>
                </h1>
                <div className="nav__menu">
                    <ul className="nav__list">
                        <NavItem
                            linkTo={"home"}
                            isActive={sectionsActivity.home}
                            iconClass={"bx bx-home-alt"}
                        />
                        <NavItem
                            linkTo={"about"}
                            isActive={sectionsActivity.about}
                            iconClass={"bx bx-user"}
                        />
                        <NavItem
                            linkTo={"skills"}
                            isActive={sectionsActivity.skills}
                            iconClass={"bx bxs-star"}
                        />
                        <NavItem
                            linkTo={"experiences"}
                            isActive={sectionsActivity.experiences}
                            iconClass={"bx bxs-hourglass"}
                        />
                        <NavItem
                            linkTo={"docs"}
                            isActive={sectionsActivity.docs}
                            iconClass={"bx bx-book-open"}
                        />
                        <NavItem
                            linkTo={"work"}
                            isActive={sectionsActivity.work}
                            iconClass={"bx bx-briefcase-alt-2"}
                        />
                        <NavItem
                            linkTo={"contact"}
                            isActive={sectionsActivity.contact}
                            iconClass={"bx bx-message-square-detail"}
                        />
                    </ul>
                </div>

                <i className="bx bx-moon change-theme" id="theme-button" onClick={element => changeTheme(element)}></i>
            </nav>
        </header>
    )
}

export default Header;