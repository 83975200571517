export const aboutBoxesData = [
    {
        boxIcon : "bx bx-award",
        title : "Experience",
        subTitle : "4 Years Working"
    },
    {
        boxIcon : "bx bx-briefcase-alt",
        title : "Completed",
        subTitle : "40+ Projects"
    },
    {
        boxIcon : "bx bx-support",
        title : "Support",
        subTitle : "Online 24/7"
    }
]