const TestimonialCard = ({ imageSource, name, description }) => {
    return (
        <>
            <img src={imageSource} alt="" className="testimonial__img" />

            <h3 className="testimonial__name">{name}</h3>
            <p className="testimonial__description">
                {description}
            </p>
        </>
    )
}

export default TestimonialCard