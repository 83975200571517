export const skillsContentData = [
    {
        title : "Backend",
        firstGroup : [
            {
                name : "Python",
                level : "Advanced"
            },
            {
                name : ".Net",
                level : "Intermediate"
            },
            {
                name : "Java",
                level : "Intermediate"
            }
        ],
        secondGroup : [
            {
                name : "Django",
                level : "Advanced"
            },
            {
                name : ".NetCore",
                level : "Intermediate"
            },
            {
                name : "PostgreSQL",
                level : "Advanced"
            }
        ],
        thirdGroup : [
            {
                name : "Flask",
                level : "Intermediate"
            },
            {
                name : "PHP",
                level : "Intermediate"
            },
            {
                name : "MySQL",
                level : "Intermediate"
            }
        ]
    },
    {
        title : "Frontend",
        firstGroup : [
            {
                name : "HTML",
                level : "Advanced"
            },
            {
                name : "React",
                level : "Intermediate"
            },
            {
                name : "Gulp",
                level : "Intermediate"
            }
        ],
        secondGroup : [
            {
                name : "CSS",
                level : "Advanced"
            },
            {
                name : "Redux",
                level : "Intermediate"
            },
            {
                name : "LESS",
                level : "Advanced"
            }
        ],
        thirdGroup : [
            {
                name : "Javascript",
                level : "Advanced"
            },
            {
                name : "AngularJS",
                level : "Intermediate"
            },
            {
                name : "JQuery",
                level : "Intermediate"
            }
        ]
    },
    {
        title : "Mobile",
        firstGroup : [
            {
                name : "Android",
                level : "Intermediate"
            },
            {
                name : "ReactNative",
                level : "Beginner"
            }
        ],
        secondGroup : [
            {
                name : "Java",
                level : "Advanced"
            },
            {
                name : "Flutter",
                level : "Beginner"
            }
        ],
        thirdGroup : [
            {
                name : "Kotlin",
                level : "Intermediate"
            }
        ]
    },
    {
        title : "Others",
        firstGroup : [
            {
                name : "C",
                level : "Advanced"
            },
            {
                name : "Git",
                level : "Intermediate"
            }
        ],
        secondGroup : [
            {
                name : "C++",
                level : "Advanced"
            },
            {
                name : "GitHub",
                level : "Intermediate"
            }
        ],
        thirdGroup : [
            {
                name : "WinForms",
                level : "Advanced"
            },
            {
                name : "Unity",
                level : "Intermediate"
            }
        ]
    }
]