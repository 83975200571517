const DocsCard = ({ title, description, url, gitHubLink }) => {
    return (
        <div className="docs__card">
            <div>
                <h3 className="docs__title">{title}</h3>
                <p className="docs__description">{description}</p>
            </div>
            <div className="docs__buttons">
                {
                    gitHubLink ?
                        <a href={gitHubLink} className="docs__button github" target="_blank" rel='noreferrer'>
                            <i className='bx bxl-github'></i>
                        </a> :
                        <span className='docs__button disabled_link'>
                            <i className='bx bxl-github'></i>
                        </span>
                }
                {
                    url.length > 0 ?
                        <a href={url} className="docs__button" target="_blank" rel='noreferrer'>
                            Read
                        </a> :
                        <span className='docs__button disabled_link'>
                            Don't Ready
                        </span>

                }

            </div>
        </div>
    )
}

export default DocsCard