export const contactCardsData = [
    {
        linkTo : "mailto:osmandurdag@hotmail.com",
        icon : "bx bx-mail-send",
        title : "Email",
        data : "osmandurdag@hotmail.com"
    },
    {
        linkTo : "https://goo.gl/maps/LVXVAxtaj4Mzqqnc7",
        icon : "bx bxs-map",
        title : "Office",
        data : "Erzurum"
    }
]