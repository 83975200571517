import englishHeaven from "../assets/img/works/english_heaven.webp";
import hospitalAppointmentSystem from "../assets/img/works/hospital_appointment_system.webp";
import gymWebSite from "../assets/img/works/responsive_gym_website.webp";
import pomodoroTimer from "../assets/img/works/pomodoro_timer.webp";
import watchesWebsite from "../assets/img/works/watch.webp";
import movieApp from "../assets/img/works/movie_app.webp";
import budgetApp from "../assets/img/works/budget_app.webp";
import calculator from "../assets/img/works/calculator.webp";
import captureToSend from "../assets/img/works/capture_to_send.webp";
import catchThePhoenix from "../assets/img/works/catch_the_phoenix.webp";
import flappyBird from "../assets/img/works/flappy_bird.webp";
import vadiErzurum from "../assets/img/works/vadiErzurum.jpg";
import drumMachine from "../assets/img/works/drumMachine.jpg";
import twentyFiveClock from "../assets/img/works/twentyFiveClock.jpg";
import randomQuoteMachine from "../assets/img/works/randomQuoteMachine.jpg";

export const WorkCardsData = [
    {
        category : "web",
        imageSource : vadiErzurum,
        title : "Vadi Erzurum",
        gitHubLink : "",
        liveDemoLink : "https://vadidiserzurum.com",
        isDemo : false
    },
    {
        category : "web",
        imageSource : englishHeaven,
        title : "English Heaven",
        gitHubLink : "https://github.com/zumrudu-anka/english-heaven",
        liveDemoLink : "https://osmandurdag.com/english-heaven",
        isDemo : true
    },
    {
        category : "web",
        imageSource : hospitalAppointmentSystem,
        title : "Hospital Appointment System",
        gitHubLink : "https://github.com/zumrudu-anka/django-hospital-appointment-system",
        liveDemoLink : "http://zumruduanka.pythonanywhere.com",
        isDemo : true
    },
    {
        category : "web",
        imageSource : gymWebSite,
        title : "Gym Website",
        gitHubLink : "https://github.com/zumrudu-anka/react-responsive-gym-website",
        liveDemoLink : "https://osmandurdag.com/react-responsive-gym-website",
        isDemo : true
    },
    {
        category : "web",
        imageSource : drumMachine,
        title : "Drum Machine",
        gitHubLink : "https://github.com/zumrudu-anka/react-drum-machine",
        liveDemoLink : "https://osmandurdag.com/react-drum-machine",
        isDemo : true
    },
    {
        category : "web",
        imageSource : pomodoroTimer,
        title : "Pomodoro Timer",
        gitHubLink : "https://github.com/zumrudu-anka/react-pomodoro-timer",
        liveDemoLink : "https://osmandurdag.com/react-pomodoro-timer",
        isDemo : true
    },
    {
        category : "web",
        imageSource : watchesWebsite,
        title : "Watches Website",
        gitHubLink : "https://github.com/zumrudu-anka/react-watches-website",
        liveDemoLink : "https://osmandurdag.com/react-watches-website",
        isDemo : true
    },
    {
        category : "web",
        imageSource : movieApp,
        title : "Movie App",
        gitHubLink : "https://github.com/zumrudu-anka/web-components-movie-app",
        liveDemoLink : "https://osmandurdag.com/web-components-movie-app",
        isDemo : true
    },
    {
        category : "web",
        imageSource : budgetApp,
        title : "Budget App",
        gitHubLink : "https://github.com/zumrudu-anka/react-budget-app",
        liveDemoLink : "https://osmandurdag.com/react-budget-app",
        isDemo : true
    },
    {
        category : "web",
        imageSource : calculator,
        title : "Calculator",
        gitHubLink : "https://github.com/zumrudu-anka/react-calculator",
        liveDemoLink : "https://osmandurdag.com/react-calculator/",
        isDemo : true
    },
    {
        category : "web",
        imageSource : randomQuoteMachine,
        title : "Random Quote Machine",
        gitHubLink : "https://github.com/zumrudu-anka/react-random-quote-machine",
        liveDemoLink : "https://osmandurdag.com/react-random-quote-machine/",
        isDemo : true
    },
    {
        category : "web",
        imageSource : twentyFiveClock,
        title : "25 + 5 Clock",
        gitHubLink : "https://github.com/zumrudu-anka/react-25-5-clock",
        liveDemoLink : "https://osmandurdag.com/react-25-5-clock/",
        isDemo : true
    },
    {
        category : "mobile",
        imageSource : catchThePhoenix,
        title : "Catch The Phoenix",
        gitHubLink : "https://github.com/zumrudu-anka/android-catch-the-phoenix",
        liveDemoLink : "",
        isDemo : true
    },
    {
        category : "desktop",
        imageSource : captureToSend,
        title : "Capture to Send",
        gitHubLink : "https://github.com/zumrudu-anka/python-tkinter-opencv-capture-to-send",
        liveDemoLink : "",
        isDemo : true
    },
    {
        category : "game",
        imageSource : flappyBird,
        title : "Flappy Bird",
        gitHubLink : "https://github.com/zumrudu-anka/unity-flappy-bird",
        liveDemoLink : "",
        isDemo : true
    }
];