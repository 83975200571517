// NOTE this component's css coming from Skills component
import { educationsData, jobsData } from '../../data/ExperiencesData';
import SkillData from './SkillData'

const Experiences = ({ experiencesSectionRef }) => {

    return (
        <section className="experiences section" id="experiences" ref = { experiencesSectionRef }>
            <span className="section__subtitle">

            </span>
            <h2 className="section__title">My Experiences</h2>

            <div className="skills__container container grid">
                <div className="skills__content">
                    <h3 className="skills__title"><i className='bx bxs-graduation'></i> Education</h3>

                    <div className="skills__box">
                        <div className="skills__group">
                            {educationsData.map((educationData, index) => (
                                <SkillData
                                    key={index}
                                    experienceType = {educationData.experienceType}
                                    graduationFromFirstLine = {educationData.graduationFromFirstLine}
                                    isHaveBrTag = {educationData.isHaveBrTag}
                                    graduationFromSecondLine = {educationData.graduationFromSecondLine}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="skills__content">
                    <h3 className="skills__title"><i className='bx bxs-briefcase'></i> Job</h3>

                    <div className="skills__box">
                        <div className="skills__group">
                            {jobsData.map((jobData, index) => (
                                <SkillData
                                    key={index}
                                    experienceType = {jobData.experienceType}
                                    graduationFromFirstLine = {jobData.graduationFromFirstLine}
                                    isHaveBrTag = {jobData.isHaveBrTag}
                                    graduationFromSecondLine = {jobData.graduationFromSecondLine}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Experiences