const DOCS_BASE_URL = "https://osmandurdag.com/docs";

export const DocsCardData = [
    {
        title: "Veri Bilimi ve Makine Öğrenmesi",
        description : "Python ile veri bilimi ve makine öğrenmesi notları",
        url : `${DOCS_BASE_URL}/data_science_and_machine_learning_with_python`,
        githubLink : ""
    },
    {
        title: "Algoritmalar",
        description : "Sıralama Algoritmaları & Arama Algoritmaları",
        url : `${DOCS_BASE_URL}/algorithms`,
        githubLink : "https://github.com/zumrudu-anka/Algorithms"
    },
    {
        title: "Veri Yapıları",
        description : "Diziler & Listeler & Ağaçlar",
        url : `${DOCS_BASE_URL}/data-structures`,
        githubLink : "https://github.com/zumrudu-anka/DataStructures"
    },
    {
        title: "Git",
        description : "Git hakkında Türkçe kaynak oluşturulması amacıyla alınan notlar",
        url : `${DOCS_BASE_URL}/git`,
        githubLink : ""
    }
];